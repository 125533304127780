import React from 'react';
import updateEventState from '../apis/updateEventState'
import { Modal, Button, Box, Typography} from '@mui/material'

const ConfirmDoneModal = (props) => {
    const handleSubmit = () => {
        let eventList=props.eventList
        eventList.splice(props.index,1)
        props.setEventList(eventList)
        updateEventState(props.items.SORT_ID,props.items.EVENT_ITEM)
        props.onHide()
        }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        };

    return (
        <Modal
            open={props.show}
            onClose={props.onHide}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                イベント完了
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                本当に「{props.items.EVENT_ITEM}」を完了状態にしますか？
                </Typography>
                <Button variant="contained" color="success" onClick={props.onHide} sx={{margin:'5% 10%'}}>いいえ</Button>
                <Button variant="contained" color="error" onClick={handleSubmit}>はい</Button>
            </Box>
        </Modal>
                );
    }

export default ConfirmDoneModal;