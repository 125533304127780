import {API_URL} from '../config/config'

async function getConfig(sortId){
    const data = {
        "sortId": sortId
    };
    const params = new URLSearchParams(data)
    const result = await fetch( API_URL + 'getConfig?' + params, { 
      method: 'get', 
      header: { 
        'Content-Type': 'application/json'
      } 
    }).then(response => {console.log(response);return response.json();})
        .then(data => {
            return data;
        })
    return result; 
    }

    export default getConfig;