import {FormControl,Alert, Snackbar} from '@mui/material'
import React, {useState} from 'react';
import TextInput from '../components/textInput'
import SubmitButton from '../components/submitButton'
import createEventDetail from '../apis/createEventDetail'
import createEvent from '../apis/createEvent'

const EventRegisterForm = (props) =>{

    //イベント詳細取得
    const [eventDetail, setEventDetail] = useState({EVENT_ID:'',SORT_ID:props.userInfo.sortId,EVENT_ITEM:'',OVERVIEW:'',OBJECTIVE:'',BUDGET:'',TERM:'',PLAN:'',DESCRIPTION:'',DATE:''})
    
    const handleChange = e => { let name = e.target.name; setEventDetail({ ...eventDetail, [name]: e.target.value }); }

    const handleSubmit = () => {
        async function fetchData() {
            let result = await createEvent(props.userInfo.sortId, eventDetail.EVENT_ITEM, props.userInfo.userType, props.userInfo.userId, eventDetail);
            if(!result.ERROR){
            let data = eventDetail
            console.log(eventDetail)
            data.EVENT_ID=result.EVENT_ID
            setShowAlert(true);
            }else{
            setShowError(true);
            }
        }
        fetchData();
    }

    const [showAlert, setShowAlert] = useState(false);
    const alert = () => setShowAlert(!showAlert);

    const [showError, setShowError] = useState(false);
    const error = () => setShowError(!showError);



    return (
        <>
      <FormControl sx={{ width: '94%', left: '3%'}}>
        <TextInput label="イベント名" name="EVENT_ITEM" value={eventDetail.EVENT_ITEM} onChange={handleChange} />
        <TextInput label="概要" name="OVERVIEW" value={eventDetail.OVERVIEW} onChange={handleChange} />
        <TextInput label="目標" name="OBJECTIVE" value={eventDetail.OBJECTIVE} onChange={handleChange} />
        <TextInput label="予算" name="BUDGET" value={eventDetail.BUDGET} onChange={handleChange} />
        <TextInput label="期間" name="TERM" value={eventDetail.TERM} onChange={handleChange} />
        <TextInput label="計画" name="PLAN" value={eventDetail.PLAN} onChange={handleChange} />
        <TextInput label="備考" name="DESCRIPTION" value={eventDetail.DESCRIPTION} onChange={handleChange} />
        <SubmitButton value='送信する' onClick={handleSubmit}/>
        </FormControl>
        <Snackbar open={showAlert} autoHideDuration={4000} onClose={alert}>
            <Alert onClose={alert} severity="success" sx={{ width: '100%' }}>登録しました</Alert>
        </Snackbar>
        <Snackbar open={showError} autoHideDuration={4000} onClose={error}>
            <Alert onClose={error} severity="error" sx={{ width: '100%' }}>既に登録されています</Alert>
        </Snackbar>
    </>
    );
}

export default EventRegisterForm;