import {API_URL} from '../config/config'
export async function getEventDetail(sortId, eventItem){
    const data = {
        "sortId": sortId,
        "eventItem": eventItem
    };
    const params = new URLSearchParams(data)
    const content = await fetch( API_URL + 'getEventDetail?' + params, { 
    method: 'get', 
    header: { 
        'Content-Type': 'application/json'
    }
    }).then(response => {return response.json();})
        .then(data => {
            return data;
        })
    return content; 
}

export default getEventDetail;