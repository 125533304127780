import React, { useState, useEffect } from 'react';
import Row from '../components/row'
import {Table,TableBody, IconButton,Box} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import UpdateEventDetailModal from './UpdateEventDetailModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import listOptionalEventDetails from '../apis/listOptionalEventDetails'
import DeleteButton from '../components/DeleteButton'

const GetAllEventsDetail = (props) => {

    //イベントデータ用State
    const [data, setData] = useState([{EVENT_ITEM:''}])

    useEffect(()=>{
        async function fetchData() {
            const result = await listOptionalEventDetails(props.userInfo.sortId);
            setData(result);
        }
        fetchData();
    }, [props.userInfo.sortId]);

    //アイコン用State
    const [selectedItemForUpdate, setSelectedItemForUpdate] = React.useState('');
    const checkItemForUpdate = (item) =>{
        return selectedItemForUpdate === item
    }
    const [selectedItemForDelete, setSelectedItemForDelete] = React.useState('');
    const checkItemForDelete = (item) =>{
        return selectedItemForDelete === item
    }

    if(data[0]['EVENT_ITEM']===''){return(<p>登録されたイベントはありません</p>)}else{
    return (
        <>
        <Box sx={{display: 'block' }}>
        {data.map((item, index) =>(
                <div  key = {index}>
                <h2>{item['EVENT_ITEM']}
                <IconButton aria-label="create" onClick={() => {setSelectedItemForUpdate(item['EVENT_ITEM'])}}><CreateIcon /></IconButton>
                <UpdateEventDetailModal show={checkItemForUpdate(item['EVENT_ITEM'])} onHide={() => setSelectedItemForUpdate('')} items={item} setEventList={setData} eventList={data} index={index}/>
                <IconButton aria-label="delete" onClick={() => {setSelectedItemForDelete(item['EVENT_ITEM'])}}><DeleteIcon /></IconButton>
                <ConfirmDeleteModal show={checkItemForDelete(item['EVENT_ITEM'])} onHide={() => setSelectedItemForDelete('')} items={item} setEventList={setData} eventList={data} index={index}/>
                <DeleteButton onClick={() => {setSelectedItemForDelete(item['EVENT_ITEM'])}} show={checkItemForDelete(item['EVENT_ITEM'])} onHide={() => setSelectedItemForDelete('')} items={item} setEventList={setData} eventList={data} index={index}/>
                </h2>
                <Table sx={{ minWidth: 50 }}> 
                <TableBody>
                    <Row items={['概要', item['OVERVIEW']]} />
                    <Row items={['目標', item['OBJECTIVE']]} />
                    <Row items={['予算', item['BUDGET']]} />
                    <Row items={['期間', item['TERM']]} />
                    <Row items={['実施方法', item['PLAN']]} />
                    <Row items={['備考', item['DESCRIPTION']]} />
                </TableBody>
                </Table>
                <br/><br/><br/><br/>
                </div>
                ))}     
                </Box>     
        </>
    );
        }
}

export default GetAllEventsDetail;