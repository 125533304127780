import React, { useState,useEffect } from 'react';
import getPlannedEvent from '../apis/getPlannedEvent';
import Navi from '../features/navi'


const Home = (props) => {

    const [values, setValues] = useState([])

    useEffect(()=>{
        async function fetchData() {
            const result = await getPlannedEvent(props.userInfo.sortId);
            setValues(result);
        }
        fetchData();
    }, [props.userInfo.sortId]);

    return (
        <>
        <Navi />
        <div><br/>
            <h1>今月のイベント</h1>
            {values.map((item, index) =>(
            <p key = {index}>{item['EVENT_ITEM']}</p>
            ))}   
        </div>
        </>
    )
}

export default Home;