import {TextField} from '@mui/material'

const TextInput = (props) =>{
    // return (
    //     <input type="text" name={props.name} id={props.name} value={props.value} onChange={props.onChange}/>
    // );
    return (
        <>
        <TextField label={props.label} variant="outlined" name={props.name} id={props.name} value={props.value} onChange={props.onChange} /><br/>
        </>
    );
}

export default TextInput;