import React from 'react';
import {FormControlLabel,Radio} from '@mui/material'

const RadioBotton = (props) => {

    return (
        <>
            {props.items.map((item, index) => (
            <FormControlLabel value={item} control={<Radio />} label={item} onChange={props.onChange} checked={item === props.value}/>
            ))}
        </>
    );
}

export default RadioBotton;