import React from 'react';
import Navi from '../features/navi'
import EventRegisterForm from '../features/eventRegisterForm'


const Home = (props) => {

    return (
        <>
        <Navi />
        <h2>新規イベント登録</h2>
        <EventRegisterForm userInfo={props.userInfo}/>
        </>
    )
}

export default Home;