import {FormControl,Alert, Snackbar} from '@mui/material'
import React, { useState, useEffect } from 'react';
import HiddenInput from '../components/hiddenInput'
import TextInput from '../components/textInput'
import SubmitButton from '../components/submitButton'
import updateEventDetail from '../apis/updateEventDetail'
import getEventDetail from '../apis/getEventDetail'

const EventDetailForm = (props) =>{

    //イベント詳細取得
    const [eventDetail, setEventDetail] = useState({EVENT_ID:'',SORT_ID:'',EVENT_ITEM:'',OVERVIEW:'',OBJECTIVE:'',BUDGET:'',TERM:'',PLAN:'',DESCRIPTION:''})
    const forGetDetail = () => {
        async function fetchData(event_item) {
            let detail = await getEventDetail(props.sortId, event_item);
            setEventDetail(detail);
        }
        fetchData(props.eventItem);
}
    
    useEffect(()=>{
    }, [eventDetail.EVENT_ITEM]);

    const handleChange = e => { let name = e.target.name; setEventDetail({ ...eventDetail, [name]: e.target.value }); }

    const handleSubmit = () => {
        let event_tmp = eventDetail
        updateEventDetail(event_tmp);
        setShowAlert(true);
    }

    useEffect(()=>{
        forGetDetail()
    }, [props.eventItem]);

    const [showAlert, setShowAlert] = useState(false);
    const alert = () => setShowAlert(!showAlert);


    if(eventDetail['EVENT_ITEM']===''){return(<p>登録されていません</p>)}else{
    return (
        <>
      <FormControl sx={{ width: '94%', left: '3%'}}>
        <HiddenInput name="EVENT_ID" value={eventDetail.EVENT_ID} />
        <HiddenInput name="SORT_ID" value={eventDetail.SORT_ID} />
        <HiddenInput name="EVENT_ITEM" value={eventDetail.EVENT_ITEM} />
        <h2 id="title">{eventDetail.EVENT_ITEM}</h2>
        <TextInput label="概要" name="OVERVIEW" value={eventDetail.OVERVIEW} onChange={handleChange} />
        <TextInput label="目標" name="OBJECTIVE" value={eventDetail.OBJECTIVE} onChange={handleChange} />
        <TextInput label="予算" name="BUDGET" value={eventDetail.BUDGET} onChange={handleChange} />
        <TextInput label="期間" name="TERM" value={eventDetail.TERM} onChange={handleChange} />
        <TextInput label="計画" name="PLAN" value={eventDetail.PLAN} onChange={handleChange} />
        <TextInput label="備考" name="DESCRIPTION" value={eventDetail.DESCRIPTION} onChange={handleChange} />
        <SubmitButton value='送信する' onClick={handleSubmit}/>
        </FormControl>
        <Snackbar open={showAlert} autoHideDuration={4000} onClose={alert}>
            <Alert onClose={alert} severity="success" sx={{ width: '100%' }}>登録しました</Alert>
        </Snackbar>
    </>
    );
}
}

export default EventDetailForm;