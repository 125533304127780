import {API_URL} from '../config/config'
async function listPlannedEventDetails(sortId){
    const data = {
        "sortId": sortId
    };
    const params = new URLSearchParams(data)
    const content = await fetch( API_URL + 'listPlannedEventDetails?' + params, { 
    method: 'get', 
    header: { 
        'Content-Type': 'application/json'
    } 
    }).then(response => {return response.json();})
        .then(data => {
            return data;
        })
    return content;
}

export default listPlannedEventDetails;