import React, { useState, useEffect } from 'react';
import listPlannedEvents from '../apis/listPlannedEvents'
import PlannedEventsRow from '../components/plannedEventsRow'
import Navi from '../features/navi'
import { Table } from "react-bootstrap";

//イベント詳細情報出力用テーブル
const GetAllPlannedEventsPage = (props) =>{
    const [values, setValues] = useState({})

    useEffect(()=>{
        async function fetchData() {
            const result = await listPlannedEvents(props.userInfo.sortId);
            setValues(result);
        }
        fetchData();
    }, [props.userInfo.sortId]);

    //Itemがない時の処理
    // if(values.EVENT_ITEM==='none'){return <NotFound />}else{
    return (
        <>
        <Navi />
        <br/>
        <h2>予定されたイベント</h2>
        {Object.keys(values).map((date, index) =>(
                <div  key = {index}>
                <Table borderless hover> 
                    <PlannedEventsRow items={[date,values[date]]} />
                </Table>
                <br/>
                </div>
                ))}           
        </>
    );
}

export default GetAllPlannedEventsPage;