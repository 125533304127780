import React from 'react';
import {IconButton} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateEventDetailModal from '../features/UpdateEventDetailModal';

const DeleteButton = (props) => {

    return (
        <>
                <IconButton aria-label="delete" onClick={props.onClick}><DeleteIcon /></IconButton>
                <UpdateEventDetailModal show={props.show} onHide={props.onHide} items={props.items} setEventList={props.setEventList} eventList={props.eventList} index={props.index}/>
        </>
    );
}

export default DeleteButton;