import React from 'react';
import ReactDOM from 'react-dom';
import liff from '@line/liff';
import Router from './router';
import {LIFF_ID} from './config/config'


//Liff初期化処理
liff.init({ liffId: LIFF_ID }).then(() => {
    if (liff.isLoggedIn()) {
        console.log('初期化');main();
    } else {
        let result = window.confirm("LINE Loginしますか？");
        if (result) { liff.login({ redirectUri: window.location.href }); }
        else { window.history.pushState({},'','notLoggedIn');ReactDOM.render(<Router />, document.getElementById("root")); }
    }
})

main();


//メイン処理（liffの初期化処理ののちに呼び出される）
async function main() {
    const context = liff.getContext();
    const url = new URL(window.location.href)
    const roomId = url.searchParams.get('room_id');

    const userInfo= {
            sortId: roomId,
            userType: 'user',
            userId: context.userId
        }



    // const userInfo = {
    //     sortId: "Cdac4fa55400772975879c3a2afaca46c",
    //     userType: 'group',
    //     userId: "U0ff6303fd83b09f0774aeae4d41a7e74"
    // }
    // const sortId = "Cdac4fa55400772975879c3a2afaca46c";





    ReactDOM.render(<Router userInfo={userInfo} />, document.getElementById("root"));
}

