import React from 'react';
import Navi from '../features/navi'
import GetAllEventsDetail from '../features/getAllEventsDetail'

//イベント詳細情報出力用テーブル
const GetAllEventDetailPage = (props) =>{

    return (
        <>
        <Navi />
        <br/>
        <GetAllEventsDetail userInfo={props.userInfo}/> 
        </>
    );
}

export default GetAllEventDetailPage;