import React from 'react';
import Navi from '../features/navi'

const NotLoggedIn = (props) =>{
    return (
        <>
        <Navi />
        <h2>You have to login</h2>
        </>
    );
}
export default NotLoggedIn;