import {API_URL} from '../config/config'

export async function createEvent(sortId, eventItem, userType, userId, items){
    const data = {
        "sortId": sortId,
        'eventItem':eventItem, 
        'userType':userType, 
        'userId':userId,
        'OBJECTIVE': items.OBJECTIVE, 
        'DESCRIPTION': items.DESCRIPTION,  
        'BUDGET': items.BUDGET, 
        'TERM': items.TERM, 
        'OVERVIEW': items.OVERVIEW, 
        'PLAN': items.PLAN,
        'DATE': items.DATE
    };
    const result = await fetch( API_URL + 'createEvent', { 
      method: 'post', 
      header: { 
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data) 
    }).then(response => {return response.json();})
        .then(data => {
            return data;
        })
    return result; 
    }

    export default createEvent;