import React, { useState, useEffect } from 'react';
import getAllEvents from '../apis/listAllEvents'
import Navi from '../features/navi'
import {FormControl,InputLabel,MenuItem,Select} from '@mui/material'
import EventDetailForm from '../features/eventDetailForm'

//イベント詳細登録用フォーム
const RegisterDetailDefaultPage = (props) =>{

    //イベントリスト取得
    const [eventList, setEventList] = useState([])
    useEffect(()=>{
        async function fetchData() {
            const result = await getAllEvents(props.userInfo.sortId);
            setEventList(result);
        }
        fetchData();
    }, [props.userInfo.sortId]);

    //イベントアイテム保持
    const [selectedItem, setSelectedItem] = useState('選んでください');
    const changeSelectedItem = e => setSelectedItem(e.target.value);

    useEffect(()=>{
    }, [selectedItem]);

    if(selectedItem==='選んでください'){
    
    return (        
        <>
        <Navi /><br/>
        <FormControl sx={{ minWidth: 300 }} margin="normal">
        <InputLabel id="demo-simple-select-label">選んでください</InputLabel>
        <Select label="選んでください" name="EVENT_ITEM" value={eventList.EVENT_ITEM}  onChange={changeSelectedItem}>
        {eventList.map((item, index) =>(
            <MenuItem key={index} value={item.EVENT_ITEM}>{item.EVENT_ITEM}</MenuItem>
        ))}   
        </Select>
      </FormControl>
        </>
    )

    }else{

    return (
        <>
        <Navi /><br/>
        <FormControl sx={{ minWidth: 300 }} margin="normal">
        <InputLabel id="demo-simple-select-label">選んでください</InputLabel>
        <Select label="選んでください" name="EVENT_ITEM" value={eventList.EVENT_ITEM}  onChange={changeSelectedItem}>
        {eventList.map((item, index) =>(
            <MenuItem key={index} value={item.EVENT_ITEM}>{item.EVENT_ITEM}</MenuItem>
        ))}   
        </Select>
      </FormControl>
      <br/>
        <EventDetailForm eventItem={selectedItem} sortId={props.userInfo.sortId} />  
    </>
);
}
}

export default RegisterDetailDefaultPage;