import {API_URL} from '../config/config'
export async function updateEventDetail(data){
    fetch( API_URL + 'updateEventDetail', { 
      method: 'post', 
      header: { 
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data) 
    });
    
}

export default updateEventDetail;