import {API_URL} from '../config/config'

export async function updateConfig(sortId, notification){
    const data = {
        "sortId": sortId,
        "notification": notification
    };
    const result = await fetch( API_URL + 'updateConfig', { 
      method: 'post', 
      header: { 
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data) 
    }).then(response => {return response.json();})
        .then(data => {
            return data;
        })
    return result; 
    }

    export default updateConfig;