import {API_URL} from '../config/config'

export async function updateEventState(sortId, eventItem){
    const data = {
        "sortId": sortId,
        'eventItem':eventItem
    };
    const result = await fetch( API_URL + 'updateEventState', { 
      method: 'post', 
      header: { 
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data) 
    }).then(response => {return response.json();})
        .then(data => {
            return data;
        })
    return result; 
    }

    export default updateEventState;