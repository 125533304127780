import React, { useState, useEffect } from 'react';
import Navi from '../features/navi'
import RadioBotton from '../../src/components/radioBotton';
import getConfig from '../apis/getConfig';
import updateConfig from '../apis/updateConfig';
import SubmitButton from '../components/submitButton'
import { FormControl, RadioGroup,FormLabel, Alert, Snackbar } from '@mui/material'

//イベント詳細情報出力用テーブル
const ConfigPage = (props) => {
    const [values, setValues] = useState([])
    const handleChange = e => { let name = e.target.name; setValues({ ...values, [name]: e.target.value }); }

    const handleSubmit = () => {
        updateConfig(props.userInfo.sortId, values.notification)
        setShowAlert(true);
    }

    useEffect(() => {
        async function fetchData() {
            const result = await getConfig(props.userInfo.sortId);
            let val = '';
            if (result.NOTIFICATION === 1) { val = 'on' } else { val = 'off' }
            setValues({ notification: val });
        }
        fetchData();
    }, [props.userInfo.sortId]);

    const [showAlert, setShowAlert] = useState(false);
    const alert = () => setShowAlert(!showAlert);



    return (
        <>
            <Navi />

            <h2>設定変更</h2>
            <FormControl component="fieldset">
                <FormLabel component="legend">通知設定</FormLabel>
                <RadioGroup row aria-label="gender" name='notification'>
                <RadioBotton items={['on', 'off']} value={values.notification} onChange={handleChange} />
                </RadioGroup>
                <SubmitButton value='送信する' onClick={handleSubmit} />
                <Snackbar open={showAlert} autoHideDuration={4000} onClose={alert}>
            <Alert onClose={alert} severity="success" sx={{ width: '100%' }}>登録しました</Alert>
        </Snackbar>
            </FormControl>
        </>
    );
}

export default ConfigPage;