import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import RegisterDetailPage from "./pages/RegisterDetailPage.js"
import RegisterDetailDefaultPage from "./pages/RegisterDetailDefaultPage.js"
import GetAllEventDetailPage from "./pages/GetAllEventDetailPage.js"
import NotFound from "./pages/notFound"
import NotLoggedIn from "./pages/notLoggedIn"
import Home from "./pages/Home"
import ConfigPage from './pages/ConfigPage.js';
import GetAllPlannedEventPage from './pages/GetAllPlannedEventsPage.js';
import EventRegisterPage from './pages/EventRegisterPage.js'
import EventDetailPage from './pages/EventDetailPage.js'

const Router = (props) => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/getAllEventDetail" element={<GetAllEventDetailPage userInfo={props.userInfo} />} />
                <Route path="/registerDetail" element={<RegisterDetailDefaultPage userInfo={props.userInfo} />} />
                <Route path="/registerDetail/:EVENT_ITEM" element={<RegisterDetailPage userInfo={props.userInfo} />} />
                <Route path="/getAllPlannedEvents" element={<GetAllPlannedEventPage userInfo={props.userInfo} />} />
                <Route path="/registerEvent" element={<EventRegisterPage userInfo={props.userInfo}/>} />
                <Route path="/config" element={<ConfigPage userInfo={props.userInfo} />} />
                <Route path="/notLoggedIn" element={<NotLoggedIn />} />
                <Route path="/" element={<Home userInfo={props.userInfo} />} />
                <Route path="*" element={<NotFound />} />
                <Route path="/EventDetailPage" element={<EventDetailPage userInfo={props.userInfo} />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router;