import React from 'react';
import {useParams} from 'react-router-dom';
import Navi from '../features/navi'
import EventDetailForm from '../features/eventDetailForm'

//イベント詳細登録用フォーム
const RegisterDetailPage = (props) =>{
    const eventItem = useParams().EVENT_ITEM;

    return (
        <>
        <Navi />
        <EventDetailForm eventItem={eventItem} sortId={props.userInfo.sortId} />
    </>
);
}

export default RegisterDetailPage;