import React, { useState } from 'react';
import HiddenInput from '../components/hiddenInput'
import TextInput from '../components/textInput'
import SubmitButton from '../components/submitButton'
import updateEventDetail from '../apis/updateEventDetail'
import { Modal, FormControl, Box, Alert, Snackbar,IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';


const UpdateEventDetailModal = (props) => {

    const [event, setEvent] = useState(props.items)
    const handleChange = e => { let name = e.target.name; setEvent({ ...event, [name]: e.target.value }); }
    const handleSubmit = () => {
        let event_tmp = event
        updateEventDetail(event_tmp);
        let eventList = props.eventList
        eventList[props.index] = event_tmp
        props.setEventList(eventList)
        setShowAlert(true);
    }
    const [showAlert, setShowAlert] = useState(false);
    const alert = () => setShowAlert(!showAlert);

    const style = {
        position: 'absolute',
        overflow: 'auto scroll hidden',
        top: '50%',
        left: '50%',
        width: '80%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    if(props.items===[]){

        return (<p>aaaaa</p>)
    }else{

    return (
        <Modal open={props.show} onClose={props.onHide} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
            <IconButton aria-label="create" onClick={props.onHide} sx={{'marginLeft':'93%'}}><CloseIcon /></IconButton>
                <FormControl sx={{ width: '94%', left: '3%'}}>
                    <HiddenInput name="EVENT_ID" value={event.EVENT_ID} />
                    <HiddenInput name="SORT_ID" value={event.SORT_ID} />
                    <HiddenInput name="EVENT_ITEM" value={event.EVENT_ITEM} />
                    <h2 id="title">{event.EVENT_ITEM}</h2>
                    <TextInput label="概要" name="OVERVIEW" value={event.OVERVIEW} onChange={handleChange} />
                    <TextInput label="目標" name="OBJECTIVE" value={event.OBJECTIVE} onChange={handleChange} />
                    <TextInput label="予算" name="BUDGET" value={event.BUDGET} onChange={handleChange} />
                    <TextInput label="期間" name="TERM" value={event.TERM} onChange={handleChange} />
                    <TextInput label="計画" name="PLAN" value={event.PLAN} onChange={handleChange} />
                    <TextInput label="備考" name="DESCRIPTION" value={event.DESCRIPTION} onChange={handleChange} />
                    <SubmitButton value='送信する' onClick={handleSubmit} />
                </FormControl>
                <Snackbar open={showAlert} autoHideDuration={4000} onClose={alert}>
                    <Alert onClose={alert} severity="success" sx={{ width: '100%' }}>登録しました</Alert>
                </Snackbar>
            </Box>
        </Modal>
    );
}
}

export default UpdateEventDetailModal;