import React from 'react';
import {TableRow,TableCell} from '@mui/material'

const Row = (props) =>{
    return (
        <>
        <TableRow>
        {props.items.map((item, index) =>(
        <TableCell key={index} align="left">{item}</TableCell>
        ))}
        </TableRow>
    </>
    );
}

export default Row;