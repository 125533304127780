import React from 'react';

const PlannedEventsRow = (props) =>{
    return (
        <>
        <thead>
        <tr>
        <td>{props.items[0]}</td>
        </tr>
        </thead>
        <tbody>
        {props.items[1].map((item, index) =>(
        <tr>
        <td key={index}>{item}</td>
        </tr>
        ))}
        </tbody>
    </>
    );
}

export default PlannedEventsRow;